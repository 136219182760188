import React from "react";
import MobileHeader from "./mobile-header";
import styled from "styled-components";
import { Link } from "gatsby";

import HeroSlider, { Slide, Nav } from "hero-slider";
import darkskies from "../assets/images/slide-darkskies.webp";
import deerfamily from "../assets/images/slide-deerfamily.webp";
import courthouse from "../assets/images/slide-courthouse.webp";

const TextBox = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.3);

  h1 {
    font-size: 1.4rem;
    color: white;
    margin-bottom: 0.5rem;
  }

  p {
    color: white;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const HomeSlidesMobile = () => {
  return (
    <>

      <MobileHeader />


      <HeroSlider
        slidingAnimation="fade"
        orientation="horizontal"
        initialSlide={1}
        style={{
          color: "#FFF"
        }}
        settings={{
          slidingDuration: 1000,
          slidingDelay: 100,
          shouldAutoplay: true,
          shouldDisplayButtons: true,
          autoplayDuration: 5000,
          height: "100vw"
        }}
      >
        <Slide
          background={{
            backgroundImage: darkskies,
            backgroundAttachment: "fixed",
            backgroundPosition: "center center"
          }}
        >
          <TextBox>
            <h1>WORK IN PA SIX</h1>
            <p>
              The PA SIX region has a dynamic and diverse industry base with
              nearly 7,000 establishments
            </p>
            <Link to="/work" className="button button-white">
              Learn more &rarr;
            </Link>
          </TextBox>
        </Slide>

        <Slide
          background={{
            backgroundImage: courthouse,
            backgroundAttachment: "fixed",
            backgroundPosition: "center center"
          }}
        >
          <TextBox>
            <h1>LIVE IN PA SIX</h1>
            <p>
              Economic vibrancy, good health care, excellent schools, affordable
              houses, and a high quality of life.
            </p>
            <Link to="/live" className="button button-white">
              Learn more &rarr;
            </Link>
          </TextBox>
        </Slide>

        <Slide
          background={{
            backgroundImage: deerfamily,
            backgroundAttachment: "fixed",
            backgroundPosition: "center center"
          }}
        >
          <TextBox>
            <h1>DISCOVER PA SIX</h1>
            <p>
              Our region is one of breathtaking views, endless outdoor
              activities, and amazing activities to keep you entertained all
              year long.
            </p>
            <Link to="/discover" className="button button-white">
              Learn more &rarr;
            </Link>
          </TextBox>
        </Slide>
        <Nav />
      </HeroSlider>
    </>
  );
};

export default HomeSlidesMobile;
