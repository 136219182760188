import React from "react";
import styled from "styled-components";
import Header from "./header";
import Headroom from "react-headroom";
import { Link } from "gatsby";

import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";
import darkskies from "../assets/images/slide-darkskies.webp";
import deerfamily from "../assets/images/slide-deerfamily.webp";
import courthouse from "../assets/images/slide-courthouse.webp";

const TextBox = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 0 4rem 0;
  position: absolute;
  bottom: 4rem;

  div {
    width: 60%;
  }

  h1 {
    color: white;
    margin-bottom: 0.3rem;
  }

  p {
    color: white;
    margin-bottom: 0;
  }
`;

const HomeSlides = () => {
  return (
    <HeroSlider
      slidingAnimation="fade"
      orientation="horizontal"
      initialSlide={1}
      style={{
        color: "#FFF"
      }}
      settings={{
        slidingDuration: 1000,
        slidingDelay: 100,
        shouldAutoplay: true,
        shouldDisplayButtons: true,
        autoplayDuration: 5000,
        height: "40vw"
      }}
    >
      <OverlayContainer>
        <Headroom>
          <Header />
        </Headroom>
      </OverlayContainer>

      <Slide
        background={{
          backgroundImage: darkskies,
          backgroundAttachment: "fixed",
          backgroundPosition: "center center"
        }}
      >
        <TextBox>
          <div>
            <h1>WORK IN PA SIX</h1>
            <p>
              The PA SIX region has a dynamic and diverse industry base with
              nearly 7,000 establishments
            </p>
          </div>
          <Link to="/work" className="button button-white">
            Learn more &rarr;
          </Link>
        </TextBox>
      </Slide>

      <Slide
        background={{
          backgroundImage: courthouse,
          backgroundAttachment: "fixed",
          backgroundPosition: "center center"
        }}
      >
        <TextBox>
          <div>
            <h1>LIVE IN PA SIX</h1>
            <p>
              Economic vibrancy, good health care, excellent schools, affordable
              houses, and a high quality of life.
            </p>
          </div>
          <Link to="/live" className="button button-white">
            Learn more &rarr;
          </Link>
        </TextBox>
      </Slide>

      <Slide
        background={{
          backgroundImage: deerfamily,
          backgroundAttachment: "fixed",
          backgroundPosition: "center center"
        }}
      >
        <TextBox>
          <div>
            <h1>DISCOVER PA SIX</h1>
            <p>
              Our region is one of breathtaking views, endless outdoor
              activities, and amazing activities to keep you entertained all
              year long.
            </p>
          </div>
          <Link to="/discover" className="button button-white">
            Learn more &rarr;
          </Link>
        </TextBox>
      </Slide>

      <Nav />
    </HeroSlider>
  );
};

export default HomeSlides;
