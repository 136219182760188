import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import GoCounties from "../components/go-counties";
import HomeSlides from "../components/home-slides";
import HomeSlidesMobile from "../components/home-slides-mobile";
import useMobile from "../utils/useMobile";
import Seo from "../components/seo";

const Index = ({ data }) => {
  const photoData = useStaticQuery(graphql`
    query {
      opengraphImage: file(
        relativePath: { eq: "slide-courthouse.jpg" }
      ) {
        sharp: childImageSharp {
          image: gatsbyImageData(layout: FIXED, width: 1200)
        }
      }
    }
  `);

  const isMobile = useMobile();

  return (
    <>
      <Seo
        title="Discover PA Six, North Central Pennsylvania - Home"
        description="The PA Six region of North Central Pennsylvania: Find Work, Live, Discover"
        featuredImage={photoData.opengraphImage.sharp.image}
      />

      {isMobile ? <HomeSlidesMobile /> : <HomeSlides />}

      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>
              Looking to improve your
              <br />
              quality of life?
            </h2>
            <p>
              Discover the PA Six region
              <br />
              of North Central Pennsylvania.
            </p>
          </div>
          <div className="intro-text">
            <p>
              The PA Six region of North Central Pennsylvania is located in the
              northern part of the Appalachian Mountain range, is part of the PA
              WILDs footprint and extends throughout Cameron, Clearfield, Elk,
              Jefferson, McKean, and Potter counties.
            </p>
            <p>
              Most of our population live in smaller boroughs or townships with
              a dynamic and diverse industry base of over 17,000 businesses.
            </p>
          </div>
        </section>

        <section>
          <p>
            This site highlights all the{" "}
            <Link to="/work">work opportunities</Link> in PA Six.{" "}
            <Link to="discover">Discover</Link> the amazing outdoor activities
            throughout all six counties. Come as a visitor,{" "}
            <Link to="/live">stay as a friend</Link>.
          </p>
        </section>

        <section>
          <GoCounties />
        </section>

        <section className="page-intro">
          <div className="intro-text intro-text-home">
            <h2>Work in PA Six</h2>
            <p>
              PA Six has something to offer any job seeker who comes to this
              six-county region. While Manufacturing, Healthcare and Social
              Assistance and Transportation and Logistics are our top
              industries, there are ample opportunities for those who have a
              passion for work in Hospitality, Leisure and Entertainment.
            </p>

            <p>
              PA SIX is known as the Powdered Metal Capital of the World lends
              itself to the entrepreneurial spirit that is found in PA Six.{" "}
            </p>

            <p>
              Workers in PA Six enjoy a delightfully short and scenic commute
              back and forth to work any day of the year. Come and work in this
              beautiful area; you can own your own business, work for a small or
              large manufacturer, become a public service or healthcare
              employee, or work for a family run business. There is a lot of
              choice and availability for a good life in PA SIX.
            </p>
            <Link className="button" to="/work">
              Learn more &rarr;
            </Link>
          </div>

          {/* Kelly's channel: src="https://www.youtube.com/embed/XQQR0cFhRgI" */}
          {/*  PA Six channel: src="https://youtu.be/embed/8BM3jYbR2BE" */}

          <div className="intro-text intro-text-video">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/8BM3jYbR2BE"
              title="Work in PA Six"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-text intro-text-video video-reorder">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/W-07PEavwxc"
              title="Live in PA Six"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div className="intro-text intro-text-home">
            <h2>Live in PA Six</h2>
            <p>
              PA Six is home to approximately 218,500 citizens, making it
              primarily rural. While there is only one combined statistical area
              (DuBois, Clearfield County), several major cities are a short
              3-hour drive away!
            </p>
            <p>
              Living in PA Six provides the best of both worlds – away from the
              hustle and bustle, but close enough to cosmopolitan areas. Access
              to I-80 from several locations enables residents to have a weekend
              get-away that supports many types of interests and recreation at
              well-known tourist attractions (i.e.; Hershey Park, Pocono
              Mountains, Lake Erie (Presque Isle State Park), Gettysburg
              Battlefield and Military Museum, Carnegie Museum, Flight 93
              National Memorial, Pittsburgh Zoo, and Philadelphia Independence
              Hall.{" "}
            </p>

            <p>
              The opportunities are endless; all with a strong sense of
              community and whose residents we like to say are the “most
              welcoming people who are the salt of the earth.”
            </p>
            <Link className="button" to="/live">
              Learn more &rarr;
            </Link>
          </div>
        </section>

        <section className="page-intro">
          <div className="intro-text intro-text-home">
            <h2>Discover PA Six</h2>

            <p>
              PA Six is busting with activities both indoors and outdoors. Home
              to several state parks, beautiful rivers, lakes with beaches that
              rival the ocean, majestic mountains, it’s an outdoor paradise! PA
              Six boasts some of the best in sports that high schools have to
              offer; football, soccer, volleyball, Track-and-Field,
              baseball/softball, hockey, and skiing.{" "}
            </p>

            <p>
              Potter & Cameron County and the surrounding areas are the best
              kept secrets for anyone who is passionate about fishing all year
              round (ice-fishing included). There are plenty of fine dining
              options as well as breweries, wineries, distilleries and quaint
              shops that have something for everyone.{" "}
            </p>

            <p>
              Just take a stroll through Kane, PA as an epicenter of some of the
              best restaurants and eateries that will marvel any urban setting.
              Numerous state parks, and plentiful lakeside recreation, rivers &
              waterways make PA SIX one of the most family friendly places to
              take young children for pleasure and entertainment.{" "}
            </p>

            <Link className="button" to="/discover">
              Learn more &rarr;
            </Link>
          </div>

          <div className="intro-text intro-text-video">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/IR5knj1ykQw"
              title="Discover PA Six"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </section>
      </main>
    </>
  );
};

export default Index;
